<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Disclaimer voor https://hostingondemand.nl</h1>
        <p><a href="/downloads/disclaimer.pdf" target="_blank">Download onze disclaimer als PDF</a></p>
        <p>Op deze pagina vindt u de disclaimer van https://hostingondemand.nl, zoals deze beschikbaar is gesteld door Hosting on Demand. In deze disclaimer geven wij aan onder welk voorbehoud wij de informatie op onze website aan u aanbieden.</p>
        <h2>Intellectueel eigendom</h2>
        <p>Het gebruik van de informatie op deze website is gratis zolang u deze informatie niet kopieert, verspreidt of op een andere manier gebruikt of misbruikt. U mag de informatie op deze website alleen hergebruiken volgens de regelingen van het dwingend recht.</p>
        <p>Zonder uitdrukkelijke schriftelijke toestemming van Hosting on Demand is het niet toegestaan tekst, fotomateriaal of andere materialen op deze website her te gebruiken. Het intellectueel eigendom berust bij Hosting on Demand.</p>
        <h2>Geen garantie op juistheid</h2>
        <p>Voor de prijzen die op onze website staan, geldt dat wij streven naar een zo zorgvuldig mogelijke weergave van de realiteit en de bedoelde prijzen. Fouten die daarbij ontstaan en herkenbaar zijn als programmeer dan wel typefouten, vormen nooit een aanleiding om een contract dan wel overeenkomst met Hosting on Demand te mogen claimen of te veronderstellen.</p>
        <p>Hosting on Demand streeft naar een zo actueel mogelijke website. Mocht ondanks deze inspanningen de informatie van of de inhoud op deze website onvolledig en of onjuist zijn, dan kunnen wij daarvoor geen aansprakelijkheid aanvaarden.</p>
        <p>De informatie en/of producten op deze website worden aangeboden zonder enige vorm van garantie en of aanspraak op juistheid. Wij behouden ons het recht voor om deze materialen te wijzigen, te verwijderen of opnieuw te plaatsen zonder enige voorafgaande medeling. Hosting on Demand aanvaardt geen aansprakelijkheid voor enige informatie die op websites staat waarnaar wij via hyperlinks verwijzen.</p>
        <h2>Wijzigingen</h2>
        <p>Mocht deze disclaimer wijzigen, dan vindt u de meest recente versie van de disclaimer van https://hostingondemand.nl op https://hostingondemand.nl/wp- content/downloads/disclaimer.pdf.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'Disclaimer',
  components: {
    Breadcrumbs,
  },
}
</script>
